//
// Select2.scss
//

@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select.ng-select-single .ng-select-container,
.ng-select.ng-select-multiple .ng-select-container {
  height: $input-height !important;
  background-color: transparent !important;
}

.ng-select .ng-select-container {
  border: $input-border-width solid $input-border-color !important;
}

.ng-dropdown-panel {
  box-shadow: $box-shadow;
  border: none !important;
  z-index: 99 !important;
  background-color: $dropdown-bg !important;

  .ng-dropdown-panel-items {
    .ng-option {
      background-color: $dropdown-bg !important;
      color: $dropdown-link-color !important;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $gray-200 !important;
}

.ng-select {
  &.ng-select-focused {
    &:not(.ng-select-opened)>.ng-select-container {
      box-shadow: none !important;
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  color: $body-color;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $primary;
  color: $white;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value-icon.left {
  &:hover {
    background-color: $primary;
    color: $white;
  }
}
